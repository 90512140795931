import { useCallback, useState } from "react";
import {
  optInToStudioSeparateStripe,
  setupStripeAccount,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
// @ts-ignore
import iso3311a2 from "iso-3166-1-alpha-2";
import { toast } from "react-toastify";
import { useIsPrimaryStudioManager } from "../../../hooks/studioHooks";
import {
  getDebugEventPrefix,
  getDebugEventUserIdPrefix,
} from "../../../utils/analyticsUtils";
import { getCountryIndex } from "../../../utils/countryUtils";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../core-ui/components/Text/Text";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../elements/ToggleSwitch/ToggleSwitch";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  ManagePaymentSettingsContainer,
  ManagePaymentSettingsContent,
  ManagePaymentSettingsHeader,
} from "./ManagePaymentSettings.styles";
import { MenuItem, Select } from "@mui/material";
import { useAtomValue } from "jotai";
import {
  activeProfileAtom,
  activeStudioProfileAtom,
} from "../../../atoms/user/activeProfileAtom";
import { isActiveUserEngineerOrStudioManagerAtom } from "../../../atoms/user/activeUserAtom";
import useActiveStudiosQuery from "../../../hooks/userHooks/useActiveStudiosQuery";

interface ManagePaymentsSettingsProps {
  showHeader?: boolean;
}

export const ManagePaymentsSettings = ({
  showHeader = true,
}: ManagePaymentsSettingsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.accountInfo.user);
  const userCountryCode = useAppSelector(
    (state) => state.accountInfo.user?.country_code,
  );
  const [selectedCountry, setSelectedCountry] = useState(
    userCountryCode
      ? iso3311a2.getCountry(userCountryCode) ?? "United States"
      : "United States",
  );
  const activeProfile = useAtomValue(activeProfileAtom);
  const activeStudioProfile = useAtomValue(activeStudioProfileAtom);
  const { refetch: refetchActiveStudios } = useActiveStudiosQuery({});
  const meetsStripeRequirements = Boolean(
    activeProfile?.meets_stripe_requirements,
  );
  const isEngineerOrStudioManager = useAtomValue(
    isActiveUserEngineerOrStudioManagerAtom,
  );
  const isPrimaryStudioManager = useIsPrimaryStudioManager(
    activeStudioProfile?.id,
  );
  const isStudioSeparateStripeToggled =
    activeStudioProfile?.separate_stripe_account_opt_in ?? false;
  const dispatch = useAppDispatch();

  const redirectToStripe = () => {
    setLoading(true);
    dispatch(
      setupStripeAccount({
        country_code: iso3311a2.getCode(selectedCountry) ?? "US",
        ...(isStudioSeparateStripeToggled && {
          studio_id: activeStudioProfile?.id,
        }),
      }),
    )
      .unwrap()
      .then((data) => {
        window.analytics.track(getDebugEventPrefix + "setup_stripe_connect", {
          user_id: `${getDebugEventUserIdPrefix}${user?.id}`,
          country_code: `${iso3311a2.getCode(selectedCountry)}`,
        });
        window.open(data.url);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOptIn = useCallback(
    async (updateObj: { stripe_opt_in?: boolean; studio_id?: number }) => {
      if (!activeStudioProfile) {
        return;
      }

      try {
        await dispatch(
          optInToStudioSeparateStripe({
            ...updateObj,
            studio_id: activeStudioProfile?.id,
          }),
        )
          .unwrap()
          .then(() => {
            return refetchActiveStudios();
          });
      } catch (error) {
        toast.error("Error opting in to separate stripe account.");
      }
    },
    [dispatch, activeStudioProfile, refetchActiveStudios],
  );

  if (!isEngineerOrStudioManager) {
    return null;
  }

  if (
    activeStudioProfile &&
    isPrimaryStudioManager &&
    !isStudioSeparateStripeToggled
  ) {
    return (
      <ManagePaymentSettingsContainer>
        <ManagePaymentSettingsHeader>
          <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
            Manage Payments
          </Text>
        </ManagePaymentSettingsHeader>
        <ManagePaymentSettingsContent>
          <ToggleSwitch
            id="enable-studio-stripe-toggle"
            label={
              <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.MEDIUM}>
                Enable Stripe payout account for Studio
              </Text>
            }
            checked={isStudioSeparateStripeToggled}
            onChange={(value) => {
              void handleOptIn({
                stripe_opt_in: value,
                studio_id: activeStudioProfile.id,
              });
            }}
            size={TOGGLE_SWITCH_SIZE.MEDIUM}
          />
        </ManagePaymentSettingsContent>
      </ManagePaymentSettingsContainer>
    );
  }

  return (
    <ManagePaymentSettingsContainer>
      {showHeader && (
        <ManagePaymentSettingsHeader>
          <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
            Manage Payments
          </Text>
        </ManagePaymentSettingsHeader>
      )}
      <ManagePaymentSettingsContent>
        {activeStudioProfile && isPrimaryStudioManager && (
          <>
            <ToggleSwitch
              id="enable-studio-stripe-toggle"
              label={
                <Text color={TEXT_COLOR.SECONDARY} size={TEXT_SIZE.MEDIUM}>
                  Enable Stripe payout account for Studio
                </Text>
              }
              checked={isStudioSeparateStripeToggled}
              onChange={(value) => {
                void handleOptIn({
                  stripe_opt_in: value,
                  studio_id: activeStudioProfile.id,
                });
              }}
              size={TOGGLE_SWITCH_SIZE.MEDIUM}
            />
            <br />
          </>
        )}
        {meetsStripeRequirements &&
          (isStudioSeparateStripeToggled ? (
            <Text variant={TextStyleVariant.P2}>
              Your studio&apos;s separate Stripe account is set up and ready.
              Click to manage your Stripe settings.
            </Text>
          ) : (
            <Text variant={TextStyleVariant.P2}>
              You&apos;re all set up to receive payments! Manage your Stripe
              account using the button below
            </Text>
          ))}
        {!meetsStripeRequirements && (
          <>
            <Text variant={TextStyleVariant.P2} color={TextColor.ERROR}>
              You will <u>NOT</u> be able to <u>WITHDRAW</u> received payments
              until you connect a Stripe account.
            </Text>
            <Text>Manage your Stripe account using the button below</Text>
          </>
        )}
        <Button
          variant={ButtonVariant.OUTLINED}
          loading={loading}
          onClick={redirectToStripe}
        >
          Manage Stripe
        </Button>
        {meetsStripeRequirements && userCountryCode && (
          <>
            <Text variant={TextStyleVariant.P3} color={TextColor.SUCCESS}>
              Stripe Account Setup complete for{" "}
              {iso3311a2.getCountry(userCountryCode)}
            </Text>
          </>
        )}
        {!meetsStripeRequirements && (
          <>
            <Text variant={TextStyleVariant.P2} weight={TEXT_WEIGHT.SEMI_BOLD}>
              Not in the United States? Select your country below
            </Text>
            <Select
              variant={"outlined"}
              value={getCountryIndex(selectedCountry)}
              onChange={(event) =>
                setSelectedCountry(
                  iso3311a2.getCountries()[event.target.value as number],
                )
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "250px",
                  },
                },
              }}
            >
              {iso3311a2.getCountries().map((country, index) => (
                <MenuItem key={country} value={index}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </ManagePaymentSettingsContent>
    </ManagePaymentSettingsContainer>
  );
};
